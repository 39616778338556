var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticStyle:{"margin-bottom":"64px"},attrs:{"app":"","clipped-left":"","color":"#fff","elevation":"0"}},[_c('v-app-bar-nav-icon',{on:{"click":_vm.toggleDrawer}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"ml-2",staticStyle:{"cursor":"pointer"},attrs:{"contain":"","max-width":"110","src":require("../../assets/openbio-logo.png")},on:{"click":function($event){return _vm.navigateTo(_vm.Route.HOME)}}},on))]}}])},[_vm._v(" "+_vm._s(_vm.$t('GO_TO_HOME'))+" ")]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.getUserShortName)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getUserName))])])],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getUserEmail))])],1)],1)],1),_c('v-divider'),_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.navigateTo(_vm.Route.PROFILE)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('PROFILE'))+" ")])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v(" mdi-account-outline ")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.navigateTo(_vm.Route.RELEASE_NOTES)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('RELEASE_NOTES'))+" ")])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v(" mdi-note-search-outline ")])],1)],1),_c('v-list-item',{on:{"click":_vm.signOut}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t('SIGNOUT'))+" ")])],1),_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-logout ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }