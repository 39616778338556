<template>
  <v-app-bar
    app
    clipped-left
    color='#fff'
    elevation='0'
    style='margin-bottom: 64px'
  >
    <v-app-bar-nav-icon @click='toggleDrawer' />
    <v-tooltip bottom>
      <template #activator='{ on }'>
        <v-img
          contain
          max-width='110'
          class='ml-2'
          src='../../assets/openbio-logo.png'
          style='cursor: pointer;'
          v-on='on'
          @click='navigateTo(Route.HOME)'
        />
      </template>
      {{$t('GO_TO_HOME')}}
    </v-tooltip>

    <v-spacer />

    <v-menu
      v-model='menu'
      :close-on-content-click='false'
      :nudge-width='200'
      offset-y
    >
      <template #activator='{ on, attrs }'>
        <v-btn
          icon
          v-bind='attrs'
          color='secondary'
          class='mr-1'
          v-on='on'
        >
          <v-icon>
            mdi-account
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list dense>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>
                mdi-account
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <v-tooltip bottom>
                  <template #activator='{ on }'>
                    <span v-on='on'>
                      {{getUserShortName}}
                    </span>
                  </template>
                  <span>{{getUserName}}</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>{{getUserEmail}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list dense class='pa-0'>
          <v-list-item @click='navigateTo(Route.PROFILE)'>
            <v-list-item-content>
              <v-list-item-title>
                {{$t('PROFILE')}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon>
                mdi-account-outline
              </v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item @click='navigateTo(Route.RELEASE_NOTES)'>
            <v-list-item-content>
              <v-list-item-title>
                {{$t('RELEASE_NOTES')}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon>
                mdi-note-search-outline
              </v-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item @click='signOut'>
            <v-list-item-content>
              <v-list-item-title class='red--text'>
                {{$t('SIGNOUT')}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon color='error'>
                mdi-logout
              </v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { Route } from '@/router';

  export default {
    name: 'AppBar',
    data: function () {
      return {
        menu: false,
        drawer: true,
        Route: Route,
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
      getUserName: function () {
        if (!this.getUser) {
          return undefined;
        }
        return this.getUser.account.name;
      },
      getUserEmail: function () {
        if (!this.getUser) {
          return undefined;
        }

        return this.getUser.account.email;
      },
      getUserShortName: function () {
        if (!this.getUser) {
          return undefined;
        }

        return this.getUser.account.name.split(' ')[0] && this.getUser.account.name.split(' ')[0].substr(0, 20);
      },
    },
    methods: {
      signOut: async function () {
        await this.$store.dispatch('auth/signOut');

        if (process.env.VUE_APP_USE_PLATFORM_LOGIN_REDIRECT === 'true') {
          window.location.assign(`${process.env.VUE_APP_SIGN_IN_URL}?redirectUrl=${btoa(window.location.href)}`);
        } else {
          this.$router.replace({ name: Route.SIGN_IN });
        }
      },
      toggleDrawer: function () {
        this.$store.dispatch('drawer/toggleDrawerVisibility');
      },
      navigateTo: function (name) {
        if (this.$router.currentRoute.name !== name) {
          this.$router.push({ name: name });
        }
      },
    },
  };
</script>
